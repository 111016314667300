export const initial = {
    count: 0,
    isLoading: false,
    tasks: [],
};

export default function createCard({ data, key, onClick, navigate }) {
    var statusToMoveCards = ["DRAFT", "CREATED", "SENT_TO_DRIVER", "REFUSED_BY_DRIVER", "ACCEPTED_BY_DRIVER"];

    return data.map(driverFreight => {
        const tags = [];

        tags.push(
            ...[
                {
                    bgcolor: "#0079BF",
                    color: "white",
                    title: driverFreight?.userDriverName ?? "Sem motorista",
                },
                {
                    bgcolor: "#0079BF",
                    color: "white",
                    title: driverFreight?.vehicle?.licensePlate ?? "Sem veículo",
                },
            ]
        );

        return {
            id: driverFreight?.identifier,
            draggable: statusToMoveCards.includes(key),
            title: driverFreight?.code,
            description:
                "INÍCIO: " +
                driverFreight?.startsAtFormatted +
                "\n" +
                "FIM: " +
                driverFreight?.endsAtFormatted +
                "\n\n" +
                "ORIGEM: " +
                driverFreight?.startingFrom +
                "\n" +
                "DESTINO: " +
                driverFreight?.destinationIn,
            label: "CTE",
            tags: tags,
            showDeleteButton: false,
            onClick: () => onClick(driverFreight?.identifier),
        };
    });
}
