import React, { useRef } from "react";
import TrelloCard from "../../components/TrelloCard";

const FreightCompleted = () => {
    const lanes = useRef({
        ARRIVED_AT_DESTINATION: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=ARRIVED_AT_DESTINATION&page_size=9999999999",
            title: "Chegada",
        },
        UNLOADING: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=UNLOADING&page_size=9999999999",
            title: "Descarregando",
        },
        // TODO: Ver com PH se esse status vai ser necessário
        // DELIVERED: {
        //     route: "/adm/driver-freight/list?status=DELIVERED&page_size=9999999999",
        //     title: "Entregue",
        // },
        DELIVER_REFUSED: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=DELIVER_REFUSED&page_size=9999999999",
            title: "Entrega recusada",
        },
        DELIVER_SIGNATURE_COLLECTED: {
            route:
                "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=DELIVER_SIGNATURE_COLLECTED&page_size=9999999999",
            title: "Assintatura feita",
        },
        DELIVER_SIGNATURE_REFUSED: {
            route:
                "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=DELIVER_SIGNATURE_REFUSED&page_size=9999999999",
            title: "Assinatura recusada",
        },
        COMPLETED: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=COMPLETED&page_size=9999999999",
            title: "Completa",
        },
    });

    return <TrelloCard lanes={lanes} />;
};

export default FreightCompleted;
